import * as React from "react"
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./logo.module.css"

export function Logo() {
    return (
        <div className={styles.container}>
            <StaticImage
                className={styles.image}
                src="../../images/clinic.png"
                alt="Gangtok Clinics Logo"
                loading="eager"
                placeholder="none" />
            <p className={styles.text}>Gangtok Clinics</p>
        </div>
    )
}