import React from "react";
import { Logo } from "../logo/logo";
import * as styles from "./footer.module.css"

export function Footer() {
    return (
        <footer className={styles.footer}>
            <div className={styles.horizontalSeparator}></div>
            <Logo />
        </footer>
    )
}