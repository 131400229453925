import React, { useState } from "react";
import * as styles from "./top-bar.module.css"
import { Logo } from "../logo/logo";
import { Link } from "gatsby";
import { Icon } from "../icon/icon";
import { useLocation } from "@reach/router";
import { navigate } from "../navigate";

import MenuImage from '../../images/menu.png'
import CloseImage from '../../images/close.png'
import { HomeIcon, PhoneIcon, VideoIcon } from "../icons";

interface Option { title: string, path: string, icon: (className: string) => React.JSX.Element }

const options: Option[] = [
    { title: "Home", path: "/", icon: HomeIcon },
    { title: "Videos", path: "/patient-videos/", icon: VideoIcon },
    { title: "Contact", path: "/contact-us/", icon: PhoneIcon }
]

interface SideBarOptions {
    isOpen: boolean;
    onClose: () => void;
}

function SideBar({ sideBarOptions }: { sideBarOptions: SideBarOptions }) {
    return (
        <div className={styles.sideNavContainer}>
            <div
                className={`${styles.overlay} ${sideBarOptions.isOpen ? styles.visible : styles.invisible}`}
                onClick={sideBarOptions.onClose}>
            </div>
            <div className={`${styles.sideNav} ${sideBarOptions.isOpen ? styles.sideNavOpen : styles.sideNavClosed}`}>
                <Icon className={styles.close} image={CloseImage} onClick={sideBarOptions.onClose} />
                <div className={styles.sideNavOptionContainer} >
                    {options.map((option) => <Option option={option} closeSideBar={sideBarOptions.onClose} />)}
                </div>
            </div>
        </div>
    )
}

export function TopBar() {
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);

    return (
        <header className={styles.header}>
            <Link to="/">
                <Logo />
            </Link>
            <div className={styles.optionContainer}>
                {options.map((option) => <Option option={option} />)}
            </div>
            <Icon className={styles.menu} image={MenuImage} onClick={() => setIsSideBarOpen(true)} />

            <SideBar sideBarOptions={{ isOpen: isSideBarOpen, onClose: () => setIsSideBarOpen(false) }} />
        </header>
    )
}

function Option({ option, closeSideBar }: { option: Option, closeSideBar?: () => void | undefined }) {
    const currentPath = useLocation().pathname;

    const onClick = () => {
        navigate(option.path)
        if (closeSideBar) closeSideBar()
    }

    return (
        <div className={styles.option} onClick={() => onClick()}>
            {option.icon(`${styles.optionImage} ${currentPath == option.path ? styles.current : ``}`)}
            <h6 className={`${styles.optionText} ${currentPath == option.path ? styles.current : ``}`}><u>{option.title}</u></h6>
        </div>
    )
}