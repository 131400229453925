import React, { MouseEventHandler } from "react";
import * as styles from "./icon.module.css"

export function Icon({ image, className, onClick }: { image: string, className?: string | undefined, onClick?: MouseEventHandler<HTMLImageElement> | undefined }) {
    return (
        <img
            className={`${styles.icon} ${className}`}
            src={image}
            onClick={onClick}
            alt="Icon" />
    )
}