import React, { useEffect, useState } from "react";
import { TopBar } from "./top-bar/top-bar";
import { useLocation } from "@reach/router";

import * as styles from "./layout.module.css"
import { Footer } from "./footer/footer";

export function Layout({ children }: { children: any }) {
    const [isShown, setIsShowing] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setIsShowing(false)
        setTimeout(() => { setIsShowing(true) }, 250)
    }, [location.pathname]);

    return (
        <main>
            <TopBar />
            <div className={`${styles.children} ${isShown ? styles.shown : styles.hidden}`}>{children}</div>
            <Footer />
        </main>
    )
}