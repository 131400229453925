import React from "react"

export const HomeIcon = (className: string) => {
    return <svg className={className} width="23" height="23" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.79248 20.4106V14.4106H13.7925V20.4106H18.7925V12.4106H21.7925L11.7925 3.41064L1.79248 12.4106H4.79248V20.4106H9.79248Z" />
    </svg>
}

export const VideoIcon = (className: string) => {
    return <svg className={className} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.5556 10V6.11111C17.5556 5.81643 17.4385 5.53381 17.2301 5.32544C17.0217 5.11706 16.7391 5 16.4444 5H3.11111C2.81643 5 2.53381 5.11706 2.32544 5.32544C2.11706 5.53381 2 5.81643 2 6.11111V17.2222C2 17.5169 2.11706 17.7995 2.32544 18.0079C2.53381 18.2163 2.81643 18.3333 3.11111 18.3333H16.4444C16.7391 18.3333 17.0217 18.2163 17.2301 18.0079C17.4385 17.7995 17.5556 17.5169 17.5556 17.2222V13.3333L22 17.7778V5.55556L17.5556 10ZM14.2222 12.7778H10.8889V16.1111H8.66667V12.7778H5.33333V10.5556H8.66667V7.22222H10.8889V10.5556H14.2222V12.7778Z" />
    </svg>
}

export const PhoneIcon = (className: string) => {
    return <svg className={className} width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.62 10.79C8.06 13.62 10.38 15.94 13.21 17.38L15.41 15.18C15.69 14.9 16.08 14.82 16.43 14.93C17.55 15.3 18.75 15.5 20 15.5C20.2652 15.5 20.5196 15.6054 20.7071 15.7929C20.8946 15.9804 21 16.2348 21 16.5V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21C15.4913 21 11.1673 19.2089 7.97918 16.0208C4.79107 12.8327 3 8.50868 3 4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H7.5C7.76522 3 8.01957 3.10536 8.20711 3.29289C8.39464 3.48043 8.5 3.73478 8.5 4C8.5 5.25 8.7 6.45 9.07 7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79Z" />
    </svg>
}